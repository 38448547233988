import React, { Component } from "react"
import { css } from '@emotion/react'
// import { navigate } from "gatsby"

// FINANCIERA COMPONENTS
import { useTheme } from '@findep/microfronts-core'
import { CardElevation, TitleIcon, ButtonDegrade } from '@findep/mf-landings-core'

//MATERIAL UI COMPONENTS
import { Grid, Container, Backdrop, Snackbar, Box } from "@material-ui/core"
import { Alert } from '@material-ui/lab';

//ICONS MATERIAL UI
import SwitchVideoIcon from '@material-ui/icons/SwitchVideo';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import WarningIcon from '@material-ui/icons/Warning';
import CircularProgress from '@material-ui/core/CircularProgress';

//SERVICIOS 
import { CataloguesService } from '../../services/LandingV4'
import { ValidacionService } from "../../services/LandingV4/validacion"
import TermsCard from "../TermsCard"



function JumioContent() {
  const { palette } = useTheme()

  const Rounded = css`
   background: ${palette.primary.main};
   border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;   
  `
  const cntTxtTwo = css`  
    width: 224px;  
    margin: 20px 20px 19px 16px;
    font-weight: normal;  
    color: ${palette.primary.main};
  `



  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={1}>
          <p css={Rounded}>1</p>
        </Grid>
        <Grid item  >
          <p css={cntTxtTwo}>
            Se te pedirá foto de tu indentificación oficial por ambos lados.
          </p>

        </Grid>
      </Grid>

      <Grid container direction="row" spacing={4} >
        <Grid item xs={1}>
          <p css={Rounded}>2</p>
        </Grid>
        <Grid item >
          <p css={cntTxtTwo}>
            Se tomará un video en el cual deberás mover tu rostro adelante y atrás.
          </p>

        </Grid>
      </Grid>
    </div>
  )
}

const sizeGrl = css`  
   font-size: 16px;
    `
const cntTxtOne = css`  
        font-weight: bold;
        color: rgba(0, 0, 0, 0.87);
        line-height: 1.5;
        letter-spacing: 0.15px;
    `
const bDrop = css`  
    z-index:9999;
    color: '#fff';
  `
export default class JumioVerifiy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flujo: !((typeof window !== 'undefined') && sessionStorage.getItem('flujo') === null) ? (typeof window !== 'undefined') ? JSON.parse(sessionStorage.getItem('flujo')) : '' : '',
      habilita: false,
      bdHabilita: false,
      errorService: false,
      tenantId: ''
    }
  }

  async componentDidMount() {
    
    const flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
    let id = flujo?.creditApplicationId
  
  
    const customers = new ValidacionService(id);
    const catData = await customers.send()
    const { tenantId } = catData.data
    this.setState({ tenantId })
  }

  async fetchJumio() {

    const flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
    let id = flujo?.creditApplicationId

    this.setState({ habilita: true, bdHabilita: true })
    const catalogues = new CataloguesService();
    catalogues.identityBiometrics(id)
      .then((response) => {
        let urlJumio = response.data.verificationUrl
        window.location.assign(urlJumio)
      })
      .catch(error => {
        this.setState({ errorService: true, habilita: false, bdHabilita: false })
      })
  }



  render() {
    return (
      <Container maxWidth="sm" justify="center" css={sizeGrl}>

        <Grid container justify="center">
          <Grid item style={{ textAlign: "center" }}>
            <TitleIcon icon={SwitchVideoIcon} title="Video verificación" size={20} textColor />
          </Grid>
        </Grid>
        <br />
        <CardElevation>
          <Grid container>
            <Grid item  >
              <p css={cntTxtOne}>
                A través de los siguientes pasos, validaremos tu indentidad:
              </p>
            </Grid>
          </Grid>
          <div>
            <JumioContent />
            {/* <br />
            <Grid container spacing={4} justify="center">
              <Grid item style={{ textAlign: "center" }}>
                <ButtonDegrade id="video-verificacion-jumio-button" textButton='IR A VIDEO-VERIFICACIÓN' onClick={() => this.fetchJumio()} disabled={this.state.habilita} icon={CameraEnhanceIcon} />
              </Grid>
            </Grid> */}
          </div>

        </CardElevation>
        <br />
        {this.state.tenantId && <TermsCard
          company={this.state.tenantId}
          textButton={"INICIAR VIDEO LLAMADA"}
          iconButton={CameraEnhanceIcon}
          isActiveAvisos={true}          
          onClick={() => this.fetchJumio()}
        />}
        <br />
        <CardElevation>
          <Grid container justify="center">
            <Grid item style={{ textAlign: "center" }}>
              <TitleIcon icon={WarningIcon} title="Recuerda" size={20} textColor />
              <br />
            </Grid>
            <br />
            <Box textAlign="center">
              Haz clic <a href="http://35.238.7.66/wp-content/uploads/2021/03/Videoverificacion.mp4" target="_blank" rel="nofollow noopener noreferrer">aquí</a> para ver un video que explica paso a paso el proceso de video verificación.<br />
              Recuerda que para continuar necesitas habilitar los permisos de tu cámara y estar en un lugar iluminado.
            </Box>
          </Grid>
        </CardElevation>
        <br />
        <Backdrop css={bDrop} open={this.state.bdHabilita} >
          <CircularProgress color="primary" />
        </Backdrop>
        <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
          <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorService: false })}>
            Lo sentimos, por el momento el servicio no se encuentra disponible
          </Alert>
        </Snackbar>

        &nbsp;

      </Container>


    )
  }
}